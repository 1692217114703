/* Chat Bubble Icon */
.chat-bubble-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    padding: 15px;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Chat Window Styling */
  .chat-window {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  /* Chat Window Header Styling */
  .chat-window-header {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Close Button Styling */
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Chat Window Content Styling */
  .chat-window-content {
    padding: 15px;
    font-size: 14px;
    text-align: center;
  }
  
  .unread_count_badge {
    position: absolute;
    top: 0;
    right: -10px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: #111827;
    align-items: center;
    display: flex;
    justify-content: center;
  }