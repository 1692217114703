@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;500;600;700&display=swap");
@import url("../src/assets/fonts/font.scss");

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../src/assets/fonts/Gilroy-Bold.ttf");
}

.Gilroy-Bold {
  font-family: "Gilroy-Bold";
}

.Sen-Bold {
  font-family: "Sen-bold";
}

.Sen-Regular {
  font-family: "Sen-Regular";
}

.Sen-ExtraBold {
  font-family: "Sen-ExtraBold";
}

.Sen-SemiBold {
  font-family: "Sen-SemiBold";
}

.Sen-Medium {
  font-family: "Sen-Medium";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.errorScreen > div {
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
}
.errorScreen {
  position: fixed;
  inset: 0;
  z-index: 999999;
  background: #00000082;
  display: none;
  place-content: center;
}
.errorScreen.shown {
  display: grid;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidebar-list {
  margin-top: 0px !important;
}

/* .sidebar-list .nav-icons {
  opacity: 0.2;
} */
.sidebar-list .active .nav-icons {
  opacity: 1;
}

.sidebar-list .active span {
  color: #ffffff;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #111827;
  border-radius: 20px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #6b7280;
  /* 4ade80 */
  border-radius: 20px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  height: 100%;
}

.input-label {
  font-size: 14px;
  font-family: "Sen", sans-serif;
}

.custom-input {
  font-family: "Sen", sans-serif;
  font-weight: 700;
}

textarea {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.unchecked-block label {
  color: black;
}

.checked-block label {
  color: #2563eb;
}

.drawer-header {
  min-height: 77px !important;
}

.checked-block {
  color: #2563eb;
  background-color: #eff6ff;
  border-color: #eff6ff;
}

.checked-block .checked-block-label {
  background: #2563eb;
  color: white;
  position: relative;
}

.checked-block svg {
  stroke: white;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

#sidebar-multi-level-sidebar {
  width: 300px;
}

.mainContentSection {
  padding-left: 300px;
  display: flex;
  flex-wrap: wrap;
}

.mainContentSection .MuiPaper-root.MuiDrawer-paperAnchorRight {
  max-width: 100% !important;
  width: 34% !important;
  top: 200px;
  bottom: 2px;
  height: auto;
  overflow: hidden;
  border: 1px solid #ccc;
}
.mainContentSection.has-banner.has-multi-caseTypes .MuiPaper-root.MuiDrawer-paperAnchorRight,
.mainContentSection.has-banner.locked-forms .MuiPaper-root.MuiDrawer-paperAnchorRight,
.mainContentSection.locked-forms.has-multi-caseTypes .MuiPaper-root.MuiDrawer-paperAnchorRight {
  top: 258px;
}
.mainInnerContent .InnerForm {
  height: calc(100vh - (200px + 85px)) !important;
}
.mainContentSection.has-banner.has-multi-caseTypes .mainInnerContent .InnerForm,
.mainContentSection.has-banner.locked-forms .mainInnerContent .InnerForm,
.mainContentSection.locked-forms.has-multi-caseTypes .mainInnerContent .InnerForm {
  height: calc(100vh - (258px + 85px)) !important;
}
.mainContentSection.has-banner.locked-forms.has-multi-caseTypes .mainInnerContent .InnerForm {
  height: calc(100vh - (320px + 85px)) !important;
}
.mainContentSection.has-banner.locked-forms.has-multi-caseTypes
  .MuiPaper-root.MuiDrawer-paperAnchorRight {
  top: 320px;
}

.mainContentSection .MuiPaper-root.MuiDrawer-paperAnchorRight .PdfBox {
  padding-bottom: 160px;
}

.mainContentSection .MuiPaper-root.MuiDrawer-paperAnchorRight .MuiBox-root.css-0 {
  padding-bottom: 0px;
  /* height: calc(100vh - 100px); */
  overflow-y: auto;
}

.closeFAQWdith,
.openFAQWdith {
  width: 100%;
}

.new-form {
  margin-top: 30px;
}

.adduser-btn {
  margin-top: 30px !important;
}

.mainInnerContent .InnerForm {
  overflow-y: auto;
  /* height: calc(100vh - 126px); */
}

/* @media screen and (min-width: 1442px) {
  .UserBox .MuiDrawer-paperAnchorRight.MuiDrawer-paperAnchorDockedRight {
    top: 144px !important;
    /* height: calc(100vh - 125px) !important; 
  }
} */

@media screen and (max-width: 767px) {
  .mainInnerContent {
    min-height: 200px;
    margin-bottom: 370px;
  }

  .header-height {
    height: 87px !important;
    margin-top: 10px;
  }

  .anonymousText {
    padding: 0 5px 10px !important;
  }

  /* .mainInnerContent .InnerForm {
    height: calc(100vh - 287px);
  }  */

  .hemburger-menu {
    flex: 1;
    justify-content: flex-end;
  }
}

.header-height {
  height: 138px;
}

.left-logo {
  height: 74px;
}

.openFooterWdith {
  padding: 20px 35px 20px 330px;
}

.closeFooterWdith {
  padding: 20px 36% 20px 330px;
}

.react-datepicker__header--custom select:focus-visible {
  outline: none;
}

.react-datepicker__header--custom select {
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgb(118, 118, 118);
}

.react-datepicker__header button {
  background: #fff;
  width: 20px;
  border-radius: 3px;
  height: 20px;
  border: 1px solid rgb(118, 118, 118);
}

.react-datepicker-popper .react-datepicker__day--keyboard-selected:hover {
  color: white;
  background: #2563eb;
}

.internal-pageLink {
  text-decoration: underline;
  margin: 10px auto 0px;
  color: #2563eb;
  /* display: table; */
}

.internal-pageURL-Link {
  text-decoration: underline;
  margin: 10px auto 0px;
  color: #2563eb;
  /* display: table; */
}

.MuiButtonBase-root.MuiButton-root.update-missing-responsive {
  font-size: 14px;
}

.MuiTypography-root.MuiTypography-h5.summary-title-responsive {
  font-size: 15px;
}

.MuiSvgIcon-root.help-icon-responsive {
  font-size: 20px;
}

.summaryScreen {
  width: 100%;
}

@media screen and (min-width: 1441px) {
  .summaryScreen {
    max-width: 1200px;
    /* margin: 0 auto; */
    /* width: 1000px; */
  }

  .progressBoxsContainer {
    width: 85% !important;
    justify-content: space-between !important;
  }

  .section-title {
    max-width: 100px;
  }
}

.openFAQWdith .mainInnerContent {
  width: calc(100% - 100px - 34%);
}

@media screen and (min-width: 1300px) {
  /* .mainContentSection {
    padding-left: 22%;
  } */
  .openFooterWdith {
    padding: 20px 35px 20px 335px;
  }

  .closeFooterWdith {
    padding: 20px 36% 20px 335px;
  }
}

/* @media screen and (min-width: 2500px) {
  .openFooterWdith {
    padding: 20px 35px 20px 13%;
  }
} */
/* @media screen and (min-width: 3500px) {
  .openFooterWdith {
    padding: 20px 35px 20px 7%;
  }
} */
/* @media screen and (max-width: 1050px) {
  .openFAQWdith .mainInnerContent {
    width: 55.5%;
  }
} */

@media screen and (max-width: 991px) {
  .CustomFlexColumn {
    display: block;
  }

  .CustomFlexColumn .CustomFlexColumnInner {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .CustomFlexColumn {
    max-width: 900px !important;
    margin: 0 auto !important;
  }
}

.formView {
  padding-bottom: inherit;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .sideBarHide #sidebar-multi-level-sidebar {
    transform: translateX(0%);
  }

  .sideBarShow #sidebar-multi-level-sidebar {
    transform: translateX(-100%);
  }

  .user_form_table_title {
    font-size: 20px !important;
  }

  .form-visualizer-title {
    font-size: 15px !important;
  }

  .drawer-header {
    min-height: 50px !important;
  }

  .mainContentSection {
    padding-left: 0px;
  }

  .mainContentSection .MuiPaper-root.MuiDrawer-paperAnchorRight {
    width: 25% !important;
  }

  .openFooterWdith {
    padding: 20px;
  }

  .MuiButtonBase-root.MuiButton-root.update-missing-responsive {
    font-size: 12px;
  }

  .MuiSvgIcon-root.help-icon-responsive {
    font-size: 18px;
  }

  .formView {
    padding-bottom: 320px !important;
    padding-top: 40px !important;
  }
}

.scrollbar-hide-responsive {
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .MuiTypography-root.MuiTypography-h5.summary-title-responsive {
    font-size: 12px;
  }

  .scrollbar-hide-responsive::-webkit-scrollbar {
    display: none;
  }
}

.custom-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: #111827;
  color: #fff;
  z-index: 1300;
}

.drawerHeaderPreview .css-skniuz {
  max-width: 100%;
}

.drawerHeaderPreview .css-skniuz .MuiTabs-flexContainer {
  justify-content: start;
  margin: 0 -4px;
  align-items: stretch;
}

.drawerHeaderPreview .css-skniuz button {
  max-width: unset;
  min-width: unset;
  margin: 0;
  padding: 0 5px;
  align-items: stretch;
  width: calc(100% / 4 - 0px);
  font-size: 11px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
  font-weight: 800;
}

.customDatepicker .ms-TextField-fieldGroup {
  border: none;
  height: 39px;
  position: relative;
}

.customDatepicker .ms-TextField-fieldGroup input {
  border: 1px solid rgb(229, 231, 235);
  border-radius: 12px;
}

.customDatepicker .ms-TextField-fieldGroup input:focus {
  border-color: #000;
}

.customDatepicker .fieldGroup-117:after {
  display: none;
}

.customDatepicker .iPhonePicker .ms-TextField-fieldGroup {
  border: 1px solid #ddd;
  border-radius: 12px;
}

.customDatepicker .msDatePickerDisabled {
  height: 20px;
  width: 20px;
  right: 21px;
  top: 8px;
  z-index: 11;
}

.ms-DatePicker-event--without-label {
  display: none;
}

.ms-TextField-fieldGroup input {
  font-family: "Sen", sans-serif !important;
  font-weight: 700 !important;
  padding-left: 24px;
}

button {
  font-family: "Sen" !important;
}

/* Progress Bar css 21-11-2023*/
.customProgressBar {
  display: flex;
  margin: 0;
}

.customProgressBar .progressbar {
  width: 100%;
  position: relative;
}

.customProgressBar .progressbar .percentage {
  height: 35px;
  width: 35px;
  background-color: #fff;
  border: 2px solid #2563eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  font-size: 13px;
}

.customProgressBar .progressbar .progress {
  position: absolute;
  top: 15px;
  left: 35px;
  width: calc(100% - 35px);
  height: 5px;
  background-color: #b79595;
}

.customProgressBar .progressbar .progress .filled {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #2563eb;
  border-radius: 0 20px 20px 0;
  left: 0;
}

.customProgressBar .progressbar .text-percentage p {
  margin: 4px 0 0;
  padding: 0 10px 0 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.customProgressBar .progressbar.end {
  width: auto;
}

.customProgressBar .progressbar.end img {
  max-width: 35px;
  margin: 1px 0 0 0;
}

.progressBarSecond .heading {
  font-size: 18px;
  font-weight: 700;
  margin: 5px auto;
}

.section-title {
  max-width: auto;
}

.progressBoxsContainer {
  width: 100%;
}

.progressBoxsContainer .progressBox {
  display: inline-block;
  min-width: 100px;
  text-align: center;
  margin: 0 !important;
}

.progressBoxsContainer .progressBox img {
  margin: 0 auto;
}

.card-button {
  border-radius: 100px !important;
  margin-left: 10px !important;
  border: none !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.card-button:hover {
  border: none !important;
}

.SummaryContent {
  max-width: 720px;
  margin-top: 0px !important;
}

.sideLogo {
  width: 60%;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-right: auto;
  display: block;
  height: 40px !important;
  object-fit: contain;
}

.summaryList .MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root {
  padding-top: 8px;
}

.intercom-app > .intercom-messenger-frame {
  height: min(704px, 100% - 160px) !important;
}

.space-between {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .sideLogo {
    width: 30%;
    height: 90%;
    overflow: hidden;
  }

  .customProgressBar {
    margin: 0px 0 0px 0px;
  }

  .summaryList {
    margin-top: 70px !important;
    margin-bottom: 210px !important;
  }
}

.summaryList {
  margin-top: 0;
}

.abc .intercom-lightweight-app {
  z-index: 1199;
}

.abc .intercom-lightweight-app-launcher {
  right: 35%;
  bottom: 100px;
}

.abc .intercom-messenger-frame {
  right: 35% !important;
}

.abc .intercom-1cdkxxy {
  right: 35% !important;
}

.intercom-namespace .intercom-1wcoz6i {
  max-height: calc(100vh - 165px) !important;
}

.intercom-1cdkxxy {
  bottom: 90px !important;
}

.intercom-messenger-frame {
  bottom: 150px !important;
}

.bottom_intercom .intercom-lightweight-app-launcher {
  bottom: 100px;
}

.bottom_intercom .intercom-g890o3 {
  bottom: 100px;
}

.bottom_intercom .intercom-namespace .intercom-dfosxs {
  bottom: 100px;
}

.abc #_hj_feedback_container {
  display: none;
}

.dialog-view-user .MuiDialog-container .MuiPaper-root {
  width: 100%;
}

.dialog-view-user .MuiDialog-container .MuiDialogContent-root {
  display: flex;
  flex-wrap: wrap;
}

.dialog-view-user .MuiDialog-container .MuiDialogContent-root > div {
  flex: 0 0 50%;
}

.userViewDetail {
  flex: 0 0 100% !important;
}

.userViewDetail .basicDetails {
  display: flex;
  flex-wrap: wrap;
}

.userViewDetail .basicDetails > div {
  flex: 0 0 50%;
}

.userViewDetail .jsonViewer {
  height: calc(100vh - 400px);
  overflow-y: auto;
}

.dialog-view-user .MuiDialogActions-root button {
  background: #2563eb;
  color: #fff;
}

.dialog-delete-user .MuiDialog-container .MuiPaper-root {
  width: 100%;
}

.dialog-delete-user .MuiDialogActions-root button:first-child {
  background: red;
  color: #fff;
}

.dialog-delete-user .MuiDialogActions-root button:last-child {
  background: #2563eb;
  color: #fff;
}

.dialog-delete-user .MuiDialogActions-root {
  border-top: 1px solid #ddd;
  padding: 15px;
}

.dialog-delete-user #alert-dialog-title {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.table-refresh-user-list .MuiPaper-root.tss-1dqj55d-MUIDataTableToolbarSelect-root {
  height: auto;
}

/* / Home page css /  */
.homepage {
  margin: 25px 0px 15px;
  border-radius: 20px;
}

.homepage h1 {
  font-size: 15px;
  margin-top: 8px;
  font-weight: 600;
}

.homepage h4 {
  font-size: 15px;
  margin-top: 10px;
  font-weight: 600;
}

.homepage Button {
  border-radius: 20px;
  margin-left: 10px;
  text-transform: capitalize;
  border: none !important;
}

.homepage Button:hover {
  border: none;
}

.homepage svg {
  font-size: 15px;
  margin-right: 4px;
}

.enter-code-input:focus-visible {
  outline: none;
}

/* First screen css */
.successful-block {
  position: relative;
  height: 100%;
  margin: 12px;
  margin-top: 25px;
}

.successful-block .successful-widget {
  position: absolute;
  background: #eff6fe;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  text-align: center;
  border: none;
  border-radius: 12px;
}

.successful-block .successful-widget .icon-box {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}
.successful-block .successful-widget .icon-box svg {
  width: 100%;
  height: 100%;
}

.successful-block .successful-widget h1 {
  font-size: 30px;
  font-weight: 600;
}

.successful-block .successful-widget p {
  margin: 10px 0px;
}

.successful-block .successful-widget Button {
  text-transform: capitalize;
  border-radius: 20px;
  background-color: #3362e4;
}

.progressBarSecond .heading {
  font-size: 18px;
  font-weight: 700;
  margin: 5px auto;
}

select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  flex: 1 1;
  padding: 0 2.5em 0 22px;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  width: 17.7em;
  height: 40px;
  line-height: 35px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #ddd;
}

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
  background: transparent;
  font-size: 12px;
  line-height: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #757575;
}

select option {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.custom-scrollbar::-webkit-scrollbar {
  height: 3px;
  border-radius: 5px;
}

.custom-scrollbar.hideScroll::-webkit-scrollbar {
  height: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

.custom-scrollbar.payment::-webkit-scrollbar {
  width: 0;
}

.noPdfText {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  margin-top: 10px;
}

.anonymousText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 45px;
  text-align: center;
  margin-top: 10px;
}

.noPdfText img {
  margin-bottom: 20px;
}

.faq_container h2 {
  color: #000 !important;
}

/* Discount page CSS */
.discountDatepicker .ms-TextField-fieldGroup {
  border: 1px solid #c4c4c4;
  height: 55px;
  border-radius: 5px;
}

.discountDatepicker .ms-TextField-fieldGroup:hover {
  border: 1px solid #000;
}

.discountDatepicker .ms-TextField-fieldGroup .ms-TextField-field {
  font-weight: 300 !important;
}

.discountCodeList .MuiPaper-root {
  box-shadow: none !important;
}

.loaderBG {
  /* background: rgba(0, 0, 0, 0.3); */
  background: #111827;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  flex-direction: column;
}

.paymentLoader {
  position: absolute;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
}

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  touch-action: pan-x pan-y;
}

.summaryRefreshIcon {
  padding: 5px;
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px #363636;
  margin-left: 10px;
  cursor: pointer;
}

.userSelect.Mui-selected {
  background-color: rgba(25, 118, 210, 0.5) !important;
}

.signout-email {
  color: #fff;
  opacity: 0.3;
  padding-left: 10px;
  font-size: 14px;
}

.paynow_individaul_section {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.paynow_individual_icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  border-width: 3px;
  border-color: black;
}

.paynow_individaul_desc {
  font-size: 14px !important;
  color: #666;
}

.loader_dialog {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.intercom-container {
  display: flex;
}

.button-style {
  display: inline-block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #0099cc;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.callout-container-style {
  margin: 0 0 15px;
  font-weight: 700;
  line-height: 18px;
  color: #1a1a1a;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #1bb15733;
}

.custom-h1-header-style {
  font-size: 16px;
  margin: 0 0 17px;
  font-weight: 700;
  line-height: 18px;
  color: #1a1a1a;
}

.custom-h2-header-style {
  font-size: 14px;
  margin: 10px 0 15px;
  font-weight: 700;
  line-height: 18px;
  color: #1a1a1a;
}

.form_preview_accordian {
  word-break: break-all;
}

.form_preview_accordian.MuiAccordion-root:before {
  height: 0;
}

.form_preview_accordian.MuiAccordion-root:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.form_preview_accordian.MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.logo_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lawyer_logo {
  width: 300px;
  height: 78px;
  padding: 10px 20px;
}

.upload-logo-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.filter_by_user {
  background-color: white;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.select_user {
  width: 30%;
}

.download_btn_container {
  align-items: center;
}

.download_client_btn {
  margin-right: 10px !important;
}

@media (max-width: 767px) {
  header {
    padding: 0 10px;
  }

  .custom-scrollbar + button + button {
    left: 0;
  }

  .progressBarSecond button {
    min-width: 22px;
    height: 26px;
    top: 44px;
  }

  nav .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
  }

  header .section-title {
    max-width: auto;
    font-size: 12px !important;
    line-height: 1 !important;
    margin-bottom: 10px !important;
  }
}

#scrollElement {
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.case-type-modal {
  position: fixed;
  inset: 0;
  background: #fff;
  z-index: 9999;
  padding: 16px;
}
.case-type-modal h2 {
  font-size: 24px;
  font-weight: 700;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.card {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.case-type-modal input:checked + .card {
  background-color: #2564eb1a !important;
  border-color: #2563eb !important;
  color: #2563eb;
}
.case-type-modal label {
  cursor: pointer;
}
.d-none {
  display: none !important;
}
.customDatepickerMonthYear > div > div {
  border-radius: 12px;
  height: 40px;
}
.customDatepickerMonthYear > div {
  width: 100%;
}
.customDatepickerMonthYear > div input {
  height: 40px;
  padding: 0 16px;
}
.MuiCalendarPicker-viewTransitionContainer::-webkit-scrollbar {
  width: 8px;
}
.MuiCalendarPicker-viewTransitionContainer::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 4px;
}

.third-party-ui {
  background-color: var(--tp-menu-bg);
  border-right: var(--tp-menu-border);
}
.third-party-ui ul li span {
  color: var(--tp-menu-item-color);
  font-weight: var(--tp-menu-item-font-weight);
}
.third-party-ui ul li span:hover {
  color: var(--tp-menu-item-hover-color);
}
.third-party-ui ul li > div.active {
  background-color: var(--tp-menu-item-active-bg);
}
.third-party-ui ul li > div.active span {
  color: var(--tp-menu-item-active-color);
}
